/* site.css */
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  box-sizing: border-box;
}

img {
  width: 100%;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after {
  content: '';
}

q:before, q:after {
  content: '';
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

button {
  border: none;
}

button:focus, button:active {
  outline: none;
}

@font-face {
  font-family: "Lato-Regular";
  src: url("../assets/fonts/Lato/Lato-Regular.woff2") format("woff2"), url("../assets/fonts/Lato/Lato-Regular.woff") format("woff"), url("../assets/fonts/Lato/Lato-Regular.ttf");
  font-display: swap;
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Lato-Bold";
  src: url("../assets/fonts/Lato/Lato-Bold.woff2") format("woff2"), url("../assets/fonts/Lato/Lato-Bold.woff") format("woff"), url("../assets/fonts/Lato/Lato-Bold.ttf");
  font-display: swap;
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Lato-BoldItalic";
  src: url("../assets/fonts/Lato/Lato-BoldItalic.woff2") format("woff2"), url("../assets/fonts/Lato/Lato-BoldItalic.woff") format("woff"), url("../assets/fonts/Lato/Lato-BoldItalic.ttf");
  font-display: swap;
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Staatliches";
  src: url("../assets/fonts/Staatliches/Staatliches-Regular.woff2") format("woff2"), url("../assets/fonts/Staatliches/Staatliches-Regular.woff") format("woff"), url("../assets/fonts/Staatliches/Staatliches-Regular.ttf");
  font-display: swap;
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "OpenSans";
  src: url("../assets/fonts/OpenSans/OpenSans-Regular.woff2") format("woff2"), url("../assets/fonts/OpenSans/OpenSans-Regular.woff") format("woff"), url("../assets/fonts/OpenSans/OpenSans-Regular.ttf");
  font-display: swap;
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "OpenSans-Bold";
  src: url("../assets/fonts/OpenSans/OpenSans-Bold.woff2") format("woff2"), url("../assets/fonts/OpenSans/OpenSans-Bold.woff") format("woff"), url("../assets/fonts/OpenSans/OpenSans-Bold.ttf");
  font-display: swap;
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "PTMono";
  src: url("../assets/fonts/PTMono/PTMono-Regular.woff2") format("woff2"), url("../assets/fonts/PTMono/PTMono-Regular.woff") format("woff"), url("../assets/fonts/PTMono/PTMono-Regular.ttf");
  font-display: swap;
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Franchise";
  src: url("../assets/fonts/Franchise/Franchise-Bold.woff2") format("woff2"), url("../assets/fonts/Franchise/Franchise-Bold.woff") format("woff"), url("../assets/fonts/Franchise/Franchise-Bold.ttf");
  font-display: swap;
  font-weight: normal;
  font-style: normal;
}

html {
  width: 100%;
  height: 100%;
}

body {
  width: 100%;
  min-height: 100%;
  overflow-x: hidden;
  position: relative;
  font-family: "Lato", Helvetica, sans-serif;
  color: #404040;
  font-size: 16px;
  padding-right: 0 !important;
  display: flex;
  flex-direction: column;
}

header, footer {
  width: 100%;
}

h1, h2, .as-h1 {
  font-family: "Staatliches", Arial, sans-serif;
  text-transform: uppercase;
  font-weight: normal;
}

h1 {
  font-size: 2.375rem;
}

h2 {
  font-size: 2.125rem;
}

h3 {
  font-size: 1.875rem;
}

h4 {
  font-size: 1.25rem;
}

a {
  text-decoration: none;
  color: inherit;
}

body.mobile-menu-open {
  overflow: hidden;
}

.container {
  max-width: 1200px;
  margin: auto;
  position: relative;
}

.white-block {
  color: #404040;
}

.gray-block {
  color: #FFFFFF;
}

.grayImage-block {
  color: #FFFFFF;
  background: url("../assets/img/bg-asfalt.png");
  background-size: cover;
}

.page-preview {
  height: 90px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 130px;
}

.page-preview_icon {
  margin-top: 10px;
  width: 35px;
  filter: invert(99%) sepia(48%) saturate(2292%) hue-rotate(324deg) brightness(102%) contrast(102%);
}

.page-preview_icon img {
  width: 100%;
}

@media all and (min-width: 992px) {
  .page-preview {
    margin-top: 205px;
    height: 200px;
  }
}

.button {
  color: #000000;
  font-family: "Staatliches", Arial, sans-serif;
  font-size: 2.5rem;
  background-color: #FFE558;
  padding: 20px 30px 15px;
  margin-top: 30px;
  cursor: pointer;
  display: inline-block;
}

.connect {
  padding: 34px 50px;
  text-align: center;
}

.connect h2 {
  margin: 0 auto 20px;
  max-width: 270px;
}

.connect p {
  font-family: "PTMono", monospace, serif;
  font-size: 1.125rem;
  line-height: 24px;
  max-width: 650px;
  margin: auto;
  color: #000000;
}

@media all and (min-width: 992px) {
  .connect {
    padding: 40px 60px;
    text-align: center;
  }
  .connect h2 {
    max-width: none;
  }
  .connect p {
    font-size: 1.5rem;
    line-height: 36px;
  }
}

.header-wrapper {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #FFFFFF;
  z-index: 1000;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.header {
  padding: 15px 0 0;
  text-align: center;
  position: relative;
}

.header .banner-logo {
  width: 316px;
  margin-bottom: 15px;
}

.burger {
  display: block;
  position: absolute;
  left: 16px;
  top: 18px;
  font-size: 1.25rem;
  width: 18px;
}

.burger .fas {
  cursor: pointer;
}

.burger.mobile-menu-open {
  display: none;
}

.nav {
  display: none;
}

.nav-list {
  display: flex;
  justify-content: space-around;
}

.nav-list a {
  font-family: "OpenSans", Helvetica, sans-serif;
  text-transform: uppercase;
  font-size: 1.25rem;
  padding-bottom: 5px;
  border-bottom: 3px solid transparent;
}

.nav-list a:hover {
  border-color: #FFE558;
}

.nav-list li.current-menu-item a {
  font-family: "OpenSans-Bold", Helvetica, sans-serif;
  border-color: #FFE558;
}

.mobile-nav {
  display: none;
  padding: 64px 0;
  background-color: #2F2F2F;
  position: absolute;
  width: 100%;
  font-family: "OpenSans-Bold", Helvetica, sans-serif;
  text-transform: uppercase;
  box-shadow: 0 2px 5px rgba(123, 123, 123, 0.5);
}

.mobile-nav .close-menu {
  position: absolute;
  right: 32px;
  top: 32px;
  font-size: 20px;
  color: white;
  cursor: pointer;
  width: 16px;
}

.mobile-nav-list {
  display: flex;
  flex-direction: column;
  font-size: 1.125rem;
}

.mobile-nav-list a {
  display: block;
  color: #FFFFFF;
  margin-bottom: 28px;
}

.mobile-nav .close-button {
  width: 100%;
  height: 55px;
  background-color: #FFE558;
  text-align: center;
  text-transform: uppercase;
  position: absolute;
  bottom: 0;
  line-height: 3.5;
  color: #2F2F2F;
  cursor: pointer;
}

.mobile-nav.open {
  display: block;
}

body.admin-bar .header-wrapper {
  top: 46px;
}

body.admin-bar div#wpadminbar {
  top: 0;
  position: fixed;
}

@media all and (min-width: 992px) {
  .header {
    padding-top: 30px;
  }
  .header .banner-logo {
    margin-bottom: 30px;
  }
  .header .burger {
    display: none;
  }
  .header .nav {
    display: block;
    margin-bottom: 25px;
  }
  .header .mobile-nav {
    display: none;
  }
  body.admin-bar .header-wrapper {
    top: 32px;
  }
}

.footer {
  background: url("../assets/img/bg-asfalt.png") no-repeat center;
  background-size: cover;
  color: #FFFFFF;
  font-family: "OpenSans", Helvetica, sans-serif;
  flex: 0 0 auto;
}

.footer-content {
  display: flex;
  flex-direction: column;
  padding: 25px;
  font-size: 0.875rem;
}

.footer-content_menu {
  flex: 0 0 100%;
  display: flex;
}

.footer-content .menu-block {
  padding: 0 15px 0 25px;
  flex: 0 0 50%;
  border-left: 1px solid white;
}

.footer-content .menu-block li:not(:last-child) {
  margin-bottom: 13px;
}

.footer-content_info {
  margin: 45px 0 0;
}

.footer-content_info > * {
  padding-left: 25px;
}

.footer-content_info .greeting {
  display: block;
  text-transform: uppercase;
  font-size: 1.25rem;
  border-bottom: 1px solid white;
  padding-bottom: 10px;
  margin-bottom: 21px;
}

.footer-content_info p:not(:last-child) {
  margin-bottom: 13px;
}

.footer-content_info .location {
  font-family: "OpenSans-Bold", Helvetica, sans-serif;
  letter-spacing: 1.4px;
}

.footer-bottom-line {
  background-color: #000000;
  font-size: 0.75rem;
}

.footer-bottom-line_content {
  display: flex;
  justify-content: space-between;
  padding: 15px 25px;
}

.footer-bottom-line .copyright {
  line-height: 16px;
  flex: 0 0 60%;
}

.footer-bottom-line .social-links {
  font-size: 1.25rem;
}

.footer-bottom-line .social-links img {
  height: 20px;
  width: auto;
  margin-left: 5px;
}

@media all and (min-width: 992px) {
  .footer {
    font-family: "Lato", Helvetica, sans-serif;
  }
  .footer-content {
    flex-direction: row;
    justify-content: space-between;
    font-size: 16px;
    letter-spacing: 1.2px;
  }
  .footer-content_menu {
    flex: 0 0 45%;
    text-transform: uppercase;
    margin: 20px 30px 0 0;
  }
  .footer-content_info {
    margin: 0;
  }
  .footer-content_info .location {
    letter-spacing: 1.6px;
  }
  .footer-bottom-line .social-links i {
    margin-left: 25px;
  }
}

@keyframes changeWords {
  0% {
    top: 6px;
  }
  10%, 20% {
    top: -28px;
  }
  30%, 40% {
    top: -62px;
  }
  50%, 60% {
    top: -96px;
  }
  70%, 80% {
    top: -130px;
  }
  90%, 100% {
    top: -164px;
  }
}

.main-page {
  position: relative;
  margin-top: 130px;
}

.main-section {
  background: url("../assets/img/Home-Img.png");
  background-size: cover;
  font-family: "Lato-Bold", Helvetica, sans-serif;
}

.main-section .description {
  max-width: 100%;
  padding: 20px 16px 30px;
  font-size: 1.125rem;
  line-height: 1.35;
}

.main-section .description p, .main-section .description i {
  text-shadow: 0 3px 5px #000000;
}

.main-section .description p {
  margin-bottom: 20px;
}

.slogan-wrapper {
  padding: 35px 16px 30px;
}

.slogan-wrapper .slogan {
  text-align: center;
  position: relative;
  overflow: hidden;
}

.slogan-wrapper .slogan h2 {
  display: inline-block;
  vertical-align: super;
}

.slogan-wrapper .slogan .animation-wrapper {
  width: 130px;
  height: 33px;
  display: inline-block;
  top: 0;
  overflow: hidden;
  position: relative;
  text-align: left;
}

.slogan-wrapper .slogan .slogan-animation {
  text-transform: uppercase;
  font-family: "Staatliches", Arial, sans-serif;
  font-size: 2.125rem;
  position: absolute;
  color: #FFE558;
  top: 6px;
  animation: changeWords 10s infinite ease-in-out;
}

.logo-wrapper {
  margin-top: 16px;
  text-align: center;
  padding: 0 20px;
}

.logo-wrapper .logo {
  max-width: 100%;
  height: auto;
}

.add-description {
  font-size: 1.25rem;
  font-family: "Lato-Bold", Helvetica, sans-serif;
  padding: 25px 16px 0;
  line-height: 24px;
}

.boxes-wrapper {
  padding: 0 18px;
  margin-bottom: 60px;
}

.boxes {
  display: flex;
  flex-direction: column;
}

.boxes .box {
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px auto 10px;
}

.boxes .box:after {
  content: "";
  display: inline-block;
  height: 9px;
  width: 140px;
  background-color: #FFE558;
  position: absolute;
  bottom: -9px;
}

.boxes .box:before {
  content: "";
  display: inline-block;
  border-bottom: 9px solid #FFE558;
  border-left: 9px solid #FFE558;
  border-right: 9px solid transparent;
  border-top: 9px solid transparent;
  position: absolute;
  bottom: -16px;
  left: 46%;
  transform: rotate(-45deg);
}

.boxes .box-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 100;
}

.boxes .box-title {
  padding: 10px 0;
  text-transform: uppercase;
  font-family: "Staatliches", Arial, sans-serif;
  font-weight: 500;
  line-height: 22px;
}

.boxes .box-description {
  display: block;
  color: #000000;
  font-size: 1.125rem;
  line-height: 24px;
}

.boxes .box-icon {
  width: 140px;
  height: 140px;
  background-color: #FFE558;
  margin: 0 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: all 0.3s;
}

.boxes .box-icon svg {
  width: 100px;
  height: 100px;
}

.boxes .box-icon use.icon-use {
  fill: #2F2F2F;
  transition: fill 0.3s;
}

.advantages {
  padding: 30px 18px 36px;
  text-align: center;
}

.advantages h2 {
  line-height: 1.25;
  margin-bottom: 20px;
}

.advantages p {
  margin: auto;
  font-size: 1.35rem;
  max-width: 912px;
  line-height: 30px;
}

@media all and (min-width: 992px) {
  .main-page {
    margin-top: 205px;
  }
  .main-section .description {
    padding: 35px 25px 15px;
    max-width: 510px;
    font-size: 1.35rem;
    line-height: 1.2;
  }
  .slogan-wrapper .slogan {
    text-align: left;
  }
  .logo-wrapper {
    position: absolute;
    top: 28px;
    left: 50%;
  }
  .add-description {
    font-size: 1.625rem;
    line-height: 36px;
    max-width: 510px;
    padding: 30px 16px;
  }
  .boxes-wrapper {
    margin-bottom: 170px;
    padding: 65px 0 0;
  }
  .boxes {
    flex-direction: row;
    justify-content: space-between;
  }
  .boxes .box {
    flex: 0 0 20%;
  }
  .boxes .box:after {
    width: 70px;
    background-color: #2F2F2F;
    transition: all 0.3s;
  }
  .boxes .box:before {
    border-bottom: 9px solid transparent;
    border-left: 9px solid transparent;
  }
  .boxes .box-icon {
    background-color: #2F2F2F;
  }
  .boxes .box-icon .icon-svg {
    display: inline-block;
    width: 100px;
    height: 100px;
    background-size: cover;
    filter: invert(75%) sepia(14%) saturate(1175%) hue-rotate(13deg) brightness(95%) contrast(89%);
  }
  .boxes .box:hover:after, .boxes .box:focus:after {
    width: 140px;
    background-color: #FFE558;
  }
  .boxes .box:hover:before, .boxes .box:focus:before {
    border-bottom: 9px solid #FFE558;
    border-left: 9px solid #FFE558;
    transition: all 0.4s ease .1s;
  }
  .boxes .box:hover .box-icon, .boxes .box:focus .box-icon {
    background-color: #FFE558;
  }
  .boxes .box:hover .box-icon .icon-svg, .boxes .box:focus .box-icon .icon-svg {
    filter: invert(12%) sepia(6%) saturate(41%) hue-rotate(321deg) brightness(93%) contrast(82%);
  }
  .boxes .box .short-title {
    max-width: 67%;
  }
  .boxes .box .very-short-title {
    max-width: 55%;
  }
  .boxes .box-description {
    display: none;
    width: 850px;
    position: absolute;
    top: 290px;
    margin: auto;
    left: 50%;
    transform: translate(-50%);
  }
  .boxes .box.active:after {
    width: 140px;
    background-color: #FFE558;
  }
  .boxes .box.active:before {
    border-bottom: 9px solid #FFE558;
    border-left: 9px solid #FFE558;
  }
  .boxes .box.active .box-icon {
    background-color: #FFE558;
  }
  .boxes .box.active .box-icon .icon-svg {
    filter: invert(12%) sepia(6%) saturate(41%) hue-rotate(321deg) brightness(93%) contrast(82%);
  }
  .boxes .box.active + .box-description {
    display: block;
  }
  .advantages {
    padding: 30px 18px 54px;
  }
  .advantages p {
    line-height: 34px;
    font-size: 1.625rem;
  }
  .advantages h2 {
    font-size: 2.875rem;
    margin-bottom: 25px;
  }
}

.slider-wrapper {
  margin-bottom: 60px;
  text-align: center;
}

.slider {
  display: block;
  height: 666px;
}

.slider .first-slide, .slider .second-slide {
  height: 616px;
  display: flex;
}

.big-part, .less-part {
  display: inline-block;
  height: 100%;
  text-align: left;
  position: relative;
  box-sizing: border-box;
}

.big-part_content, .less-part_content {
  margin: 28px 16px;
}

.big-part {
  color: #000000;
  background-color: #FFE558;
  width: 75%;
  border-bottom: 1px solid #FFE558;
}

.big-part .as-h1 {
  font-size: 55px;
}

.big-part h4 {
  font-size: 1.625rem;
}

.big-part h5 {
  font-size: 1.25rem;
  line-height: 26px;
}

.big-part h1, .big-part h2, .big-part h3, .big-part h4, .big-part h5, .big-part h6 {
  font-family: "Lato-Bold", Helvetica, sans-serif;
}

.big-part p, .big-part h1, .big-part h2, .big-part h3, .big-part h4, .big-part h5, .big-part h6 {
  margin: 20px 16px 25px 55px;
}

.big-part p {
  font-size: 1.25rem;
  line-height: 1.3;
}

.big-part_ship {
  width: auto;
  margin-left: 15%;
}

.big-part .icons-block {
  display: flex;
  justify-content: flex-end;
  margin-top: 12px;
  max-width: 735px;
}

.big-part .icons-block .icon {
  width: auto;
  height: 74px;
}

.big-part .icons-block .icon:first-child {
  margin-right: 15px;
}

.less-part {
  width: 25%;
  background-color: #FFFFFF;
  color: #000000;
  border-bottom: 1px solid #787878;
  position: relative;
}

.less-part:after, .less-part:before {
  content: "";
  display: block;
  position: absolute;
  bottom: 175px;
  background-image: url("../assets/img/triangle.png");
  width: 51px;
  height: 141px;
  z-index: 10;
}

.less-part:after {
  left: -15%;
}

.less-part:before {
  right: -15%;
  transform: rotate(180deg);
}

.less-part .as-h1 {
  font-size: 62px;
}

.less-part .as-h1 span {
  font-size: 48px;
}

.less-part_ship {
  max-width: 200px;
}

.less-part .icons-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 65px;
}

.less-part .icon {
  max-width: 77px;
  margin-bottom: 28px;
}

.slick-next, .slick-prev {
  display: block;
  position: absolute;
  top: 30px;
  width: 290px;
  height: 120px;
  cursor: pointer;
}

.slick-next.slick-arrow {
  right: 0;
}

.slick-prev.slick-arrow {
  left: 0;
  z-index: 1000;
}

.custom-dots {
  display: flex;
  justify-content: center;
  margin-top: 12px;
}

.custom-dots button {
  display: none;
}

.custom-dots li {
  background: transparent;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid #787878;
  margin-right: 10px;
}

.custom-dots .slick-active {
  background: #787878;
}

.mobile-about-page {
  display: none;
}

@media all and (max-width: 992px) {
  .slider {
    display: none;
  }
  .mobile-about-page {
    display: block;
  }
  .big-part.mobile, .big-part.mobile-white {
    border: none;
    width: 100%;
  }
  .big-part.mobile .as-h1, .big-part.mobile-white .as-h1 {
    font-size: 1.875rem;
    max-width: 700px;
    display: block;
    margin: auto;
  }
  .big-part.mobile p, .big-part.mobile-white p {
    margin: 16px auto 0;
  }
  .big-part.mobile .description, .big-part.mobile-white .description {
    font-size: 1.125rem;
  }
  .big-part.mobile .bold-description, .big-part.mobile-white .bold-description {
    font-size: 1.125rem;
    line-height: 26px;
  }
  .big-part.mobile .bold-description.important, .big-part.mobile-white .bold-description.important {
    font-size: 1.5rem;
    margin-left: auto;
    padding-bottom: 10px;
  }
  .big-part.mobile .big-part_ship, .big-part.mobile-white .big-part_ship {
    max-width: 127px;
    margin: auto;
    display: block;
  }
  .big-part.mobile .icons-block, .big-part.mobile-white .icons-block {
    justify-content: center;
    max-width: none;
    margin-top: 28px;
  }
  .big-part.mobile:after {
    content: "";
    display: block;
    width: 83px;
    height: 30px;
    position: absolute;
    background-image: url("../assets/img/triangle-orange.png");
    background-size: cover;
    bottom: -30px;
    left: calc(50% - 41px);
    z-index: 10;
  }
  .big-part.mobile-white {
    padding-top: 40px;
    background-color: #FFFFFF;
  }
}

.white-block.services-block {
  color: #000000;
}

.services {
  padding: 30px 16px;
}

.services .icon {
  background-color: #FFE558;
  margin: 0 auto 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: all 0.3s;
}

.services .icon img {
  width: 80%;
  height: 80%;
}

.services-img {
  width: 237px;
  height: 237px;
}

.services .title {
  font-family: "PTMono", monospace, serif;
  font-weight: bold;
}

.services-title {
  font-size: 1.625rem;
  margin-bottom: 20px;
  text-align: center;
}

.services-description {
  font-size: 1.125rem;
  line-height: 1.35;
}

.services-description:not(:last-child) {
  margin-bottom: 10px;
}

.services .including-title {
  font-size: 1.35rem;
  text-align: center;
}

.services .including-img {
  width: 111px;
  height: 111px;
}

.services .including-list {
  margin: 5px auto 20px;
  font-size: 1.125rem;
  text-align: center;
}

.services .including-list li {
  margin-bottom: 12px;
}

@media all and (min-width: 992px) {
  .white-block .services .services-img {
    float: left;
    margin-right: 60px;
  }
  .grayImage-block .services .services-img {
    float: right;
    margin-left: 60px;
  }
  .services-block {
    display: flex;
  }
  .services-title {
    margin-bottom: 30px;
    text-align: left;
  }
}

@media all and (min-width: 768px) {
  .including-blocks {
    display: flex;
    justify-content: space-around;
    padding-top: 20px;
  }
}

.partner .boxes {
  position: relative;
}

.partner .boxes .box-description p {
  font-size: 1.125rem;
}

.partner .boxes .box-description p:not(:last-child) {
  margin-bottom: 15px;
}

.partner .boxes .box-description .bold-description {
  font-family: "Lato-Bold", Helvetica, sans-serif;
}

.partner .partner-main-text {
  margin: 35px auto;
  font-size: 1.125rem;
  max-width: 990px;
  color: #000000;
}

.partner .partner-main-text p {
  margin-bottom: 32px;
  line-height: 26px;
}

.partner .boxes-title {
  font-family: "PTMono", monospace, serif;
  font-weight: bold;
  text-align: center;
  font-size: 1.35rem;
}

.current-partner {
  padding: 24px 0 37px;
}

.current-partner-title {
  text-align: center;
  margin-bottom: 15px;
  font-family: "Staatliches", Arial, sans-serif;
  text-transform: uppercase;
  font-weight: normal;
}

.current-partner-images {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.current-partner-images .partner-logo {
  margin-bottom: 25px;
  width: auto;
  font-family: "Franchise", "Staatliches", Arial, sans-serif;
  font-size: 2.5rem;
  text-transform: uppercase;
  max-width: 25%;
  text-align: center;
}

@media all and (min-width: 768px) {
  .current-partner-images {
    flex-direction: row;
    justify-content: space-around;
    max-width: 860px;
    margin: auto;
  }
  .current-partner-images span {
    font-size: 2.5rem;
  }
}

@media all and (min-width: 992px) {
  .partner .boxes-wrapper {
    margin-bottom: 170px;
  }
  .partner .boxes-wrapper .partner-main-text {
    font-size: 1.25rem;
  }
  .partner .boxes-title {
    font-size: 1.75rem;
  }
  .partner .boxes .box {
    flex: 0 0 15%;
  }
  .partner .boxes .box-description {
    width: 990px;
    padding: 0 16px;
  }
  .partner .boxes .box-description p {
    font-size: 1.25rem;
    line-height: 26px;
  }
  .partner .partner-main-text {
    padding: 0 16px;
  }
}

@media all and (min-width: 1024px) {
  .partner .boxes .box-description {
    padding: 0;
  }
  .partner .boxes .box-description p {
    font-size: 1.25rem;
    line-height: 26px;
  }
  .partner .partner-main-text {
    padding: 0;
  }
}

.contacts-wrapper {
  margin-top: 130px;
}

.contacts {
  display: flex;
  flex-direction: column;
}

.contacts_left-part, .contacts_right-part {
  box-sizing: border-box;
  flex: 0 0 100%;
}

.contacts-info {
  padding: 20px 0;
  text-align: center;
}

.contacts-info_block {
  margin-top: 20px;
}

.contacts-info_img {
  margin-bottom: 20px;
}

.contacts-info_img img {
  width: auto;
}

.contacts-logo {
  display: none;
}

.contacts_right-part {
  background-color: #FFE558;
  padding: 16px;
  display: flex;
  flex-direction: column;
}

.contacts_right-part .form-title {
  font-family: "PTMono", monospace, serif;
  font-size: 1.5rem;
  text-transform: uppercase;
  text-align: center;
  margin: 30px auto 22px;
}

.contacts_right-part .form {
  height: 100%;
  background-color: #FFFFFF;
  padding-top: 16px;
}

.contacts_right-part .form iframe {
  width: 1px;
  min-width: 100%;
  min-height: 100vh;
  height: 100%;
}

@media all and (min-width: 992px) {
  .contacts-wrapper {
    margin-top: 205px;
  }
  .contacts {
    flex-direction: row;
  }
  .contacts_left-part {
    flex: 0 0 54.5%;
    float: left;
  }
  .contacts_left-part .contacts-info {
    padding: 60px 0 90px;
  }
  .contacts_left-part .contacts-info_block {
    margin-top: 70px;
  }
  .contacts_left-part .contacts-logo {
    display: block;
    margin: 10% auto 46px;
    text-align: center;
    max-width: 509px;
  }
  .contacts_right-part {
    flex: 0 0 45.5%;
    padding: 0 25px;
  }
}

.FAQs-wrapper {
  padding: 20px 16px 25px;
}

.FAQs-wrapper .FAQs-block {
  margin-bottom: 25px;
}

.FAQs-wrapper .FAQs-block_question {
  background-color: #2F2F2F;
  display: flex;
  justify-content: space-between;
  padding: 16px;
}

.FAQs-wrapper .FAQs-block .abbr {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 2rem;
  line-height: 1.6;
}

.FAQs-wrapper .FAQs-block_abbr {
  width: 50px;
  margin: -16px;
  background: #FFE558;
}

.FAQs-wrapper .FAQs-block_title {
  flex: 0 0 70%;
  color: #FFFFFF;
  text-transform: uppercase;
  font-size: 1.125rem;
  line-height: 24px;
  font-weight: bold;
}

.FAQs-wrapper .FAQs-block_arrow {
  width: 28px;
  filter: invert(82%) sepia(41%) saturate(625%) hue-rotate(351deg) brightness(105%) contrast(106%);
}

.FAQs-wrapper .FAQs-block_arrow img {
  width: 100%;
}

.FAQs-wrapper .FAQs-block .answer {
  display: none;
  border: 1px solid #FFE558;
}

.FAQs-wrapper .FAQs-block .answer_abbr {
  width: 48px;
  background: #F4F3F2;
  color: #BFBFBF;
  flex-shrink: 0;
  border-right: 1px solid #FFE558;
  padding-top: 8px;
}

.FAQs-wrapper .FAQs-block .answer_text {
  padding: 20px 15px 10px;
  font-family: "OpenSans", Helvetica, sans-serif;
  font-size: 16px;
  line-height: 22px;
  color: black;
}

.FAQs-wrapper .FAQs-block.open .answer {
  display: flex;
}

.FAQs-wrapper .FAQs-block.open .FAQs-block_question {
  background-color: #FFE558;
}

.FAQs-wrapper .FAQs-block.open .FAQs-block_question .FAQs-block_abbr {
  background-color: #2F2F2F;
  color: #FFFFFF;
}

.FAQs-wrapper .FAQs-block.open .FAQs-block_question .FAQs-block_title {
  color: #000000;
}

.FAQs-wrapper .FAQs-block.open .FAQs-block_question .FAQs-block_arrow {
  transform: rotate(180deg);
  transform-origin: 50% 10px;
  filter: invert(13%) sepia(58%) saturate(13%) hue-rotate(24deg) brightness(96%) contrast(87%);
}

@media all and (min-width: 768px) {
  .FAQs-wrapper .FAQs-block_title {
    flex: 0 0 80%;
  }
}

@media all and (min-width: 992px) {
  .FAQs-wrapper .FAQs-block_title {
    flex: 0 0 80%;
  }
}

@media all and (min-width: 1200px) {
  .FAQs-wrapper {
    padding: 50px 0 10px;
  }
  .FAQs-wrapper .FAQs-block {
    margin-bottom: 60px;
  }
  .FAQs-wrapper .FAQs-block .abbr {
    font-size: 50px;
  }
  .FAQs-wrapper .FAQs-block_question {
    padding: 30px 25px;
  }
  .FAQs-wrapper .FAQs-block_abbr {
    width: 87px;
    margin: -30px -25px;
    line-height: 2.5;
  }
  .FAQs-wrapper .FAQs-block_title {
    font-size: 1.5rem;
  }
  .FAQs-wrapper .FAQs-block_arrow {
    width: 58px;
  }
  .FAQs-wrapper .FAQs-block .answer_abbr {
    width: 85px;
    padding-top: 0;
  }
  .FAQs-wrapper .FAQs-block .answer_text {
    font-size: 1.25rem;
    line-height: 30px;
    padding-right: 56px;
  }
}

.staff-wrapper {
  color: #000000;
}

.staff {
  display: flex;
  flex-wrap: wrap;
}

.staff-cart {
  height: 422px;
  color: inherit;
  cursor: pointer;
  width: calc(100% - 70px);
  min-height: 422px;
  max-width: 350px;
  perspective: 1000px;
  margin: 35px auto;
  position: relative;
}

.staff .front, .staff .back {
  text-align: center;
  position: absolute;
  height: 100%;
  width: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  transition: ease-in-out 600ms;
}

.staff .staffer-info {
  font-family: "PTMono", monospace, serif;
  width: 100%;
  text-align: center;
  padding: 35px 0 45px;
}

.staff .staffer-info .staffer-name {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.625rem;
}

.staff .staffer-info .staffer-title {
  font-size: 1.35rem;
}

.staff .front {
  font-weight: 600;
  color: #fff;
  overflow: hidden;
  background-color: #2F2F2F;
}

.staff .front .staffer-photo img {
  min-width: 100%;
  width: auto;
  height: auto;
  margin: auto;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  min-height: 60%;
}

.staff .front .staffer-info {
  background-color: #2F2F2F;
  position: absolute;
  bottom: 0;
}

.staff .back {
  background: #FFE558;
  transform: rotateY(-180deg);
  color: #000000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.staff .back .staffer-info {
  padding: 28px 0 28px;
}

.staff .staffer-bio {
  background: #FFFFFF;
  margin: 10px;
  font-size: 1.25rem;
  flex-grow: 1;
  padding: 30px 15px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  justify-content: flex-start;
  text-align: left;
}

.staff .staffer-bio .text {
  overflow: hidden;
}

.staff-cart:hover .front {
  transform: rotateY(180deg);
}

.staff-cart:hover .back {
  transform: rotateY(0deg);
}

.staff-page_button {
  width: 80%;
  max-width: 536px;
  margin: 30px auto 50px;
  display: block;
  text-align: center;
}

.staff-page_button img {
  width: 45px;
  margin-left: 16px;
  filter: invert(18%) sepia(55%) saturate(19%) hue-rotate(6deg) brightness(93%) contrast(102%);
}

.staff_bottom-line {
  display: none;
  margin-top: 25px;
}

.staff-page-link {
  text-transform: uppercase;
  font-size: 1.125rem;
  display: block;
  margin: 16px 0 0 32px;
}

.staff-page-link img {
  width: 46px;
  margin-right: 16px;
}

@media all and (min-width: 600px) {
  .staff-cart {
    width: calc(50% - 70px);
    min-width: calc(50% - 70px);
    margin: 35px;
  }
}

@media all and (min-width: 992px) {
  .staff-cart {
    width: calc(33% - 70px);
    min-width: calc(33% - 70px);
  }
  .staff_bottom-line {
    display: flex;
    margin-top: 25px;
  }
  .staff_bottom-line img {
    width: 50%;
  }
}

.employment-wrapper {
  flex: 1 0 auto;
}

.employment {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 0px;
}

.employment-title {
  font-family: "Staatliches", Arial, sans-serif;
  font-size: 1.875rem;
  border-bottom: 10px solid #FFE558;
  padding: 6px 44px;
}

.employment-description {
  margin-top: 10px;
  font-family: "PTMono", monospace, serif;
  font-size: 1.35rem;
}

.employment-add-info {
  margin-top: 30px;
  font-style: italic;
  font-size: 1.125rem;
}

@media all and (min-width: 992px) {
  .employment-title {
    font-size: 45px;
  }
  .employment-description {
    font-size: 1.875rem;
  }
  .employment-add-info {
    font-size: 1.5rem;
  }
}

.text {
  padding: 0 18px;
  margin: 30px auto;
}

.text h1, .text h2, .text h3 {
  font-family: "Staatliches", Arial, sans-serif;
  font-weight: normal;
  text-transform: uppercase;
}

.text h4, .text h5, .text h6 {
  font-family: "Lato-Bold", Helvetica, sans-serif;
  font-size: 24px;
}

.text a {
  color: blue;
  font-size: 16px;
  text-decoration: underline;
}

.text a:hover {
  text-decoration: none;
}

.text p:not(:last-child) {
  margin-bottom: 30px;
}

@media all and (min-width: 992px) {
  .text {
    font-size: 18px;
    line-height: 1.3;
  }
  .text a {
    font-size: 18px;
  }
}
