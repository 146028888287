/*
yellow: FFE558
gray: 404040
light-gray: 808080

font-family: "Lato", Helvetica, sans-serif

*/

/* General */
html {
  font-size: 100%;
}

body {
  line-height: 1.4;
}

small {
  font-size: 80%;
}

.clearfix::after {
  content: '';
  display: block;
  clear: both;
}

/* Page Headers */
.page-preview {
  max-height: 80px;
}

@media (min-width: 600px) {
  .page-preview {
    max-height: 100px;
  }
}



/* General */
.wrapper--custom a {
  color: #808080;
}

.wrapper--custom a:hover,
.wrapper--custom a:active,
.wrapper--custom a:focus {
  text-decoration: underline;
}



/* Main Content */
.main-content {
  font-size: 112.5%;
  padding: 0 20px;
}

@media only screen and (min-width: 600px) {
  .main-content {
    padding: 0 40px;
  }
}

.main-content p,
.main-content ul,
.main-content ol,
.main-content h1,
.main-content h2,
.main-content h3,
.main-content h4,
.main-content h5,
.main-content h6,
.main-content table,
.main-content blockquote {
  margin: 0 0 1rem;
}

.main-content a {
  color: #808080;
}

.main-content a:hover,
.main-content a:active,
.main-content a:focus {
  text-decoration: underline;
}

.main-content table {
  width: 100%;
}

.main-content ul,
.main-content ol {
  margin-left: 20px;
  padding-left: 20px;
  list-style: initial;
}

.main-content ol {
  list-style: decimal;
}

.main-content img {
  width: auto;
}


/* Blog Layout */
.container--single-column,
.container--two-column {
  padding: 20px 0;
}

.container--single-column {
  max-width: 1024px;
}

@media (min-width: 600px) {
  .container--single-column,
  .container--two-column {
    padding: 40px 0;
  }
}

@media (min-width: 768px) {
  .container--single-column {
    padding: 60px 0;
  }

  .container--two-column {
    padding: 60px 0;
    display: flex;
    align-items: flex-start;
  }
}

.container--two-column .main-content {
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .container--two-column .main-content {
    width: calc(100% - 330px);
  }
}

.sidebar-wrapper {
  margin-top: 40px;
}

@media (min-width: 768px) {
  .sidebar-wrapper {
    margin-top: 0;
    padding-left: 40px;
    width: 330px;
  }
}

.sidebar .widgettitle {
  margin-bottom: 20px;
}


/* Sidebar */
.sidebar:nth-child(n+2) {
  margin-top: 30px;
}

.sidebar--dynamic {
  padding-left: 20px;
  border-width: 0 0 0 8px;
  border-style: solid;
  border-color: #ccc;
}

.sidebar--dynamic ul {
  margin-left: 0;
  line-height: 1.2;
  list-style: none;
}

.sidebar--dynamic>ul>li:nth-child(n+2) {
  margin-top: 30px;
}

.sidebar--dynamic ul ul li:nth-child(n+2) {
  margin-top: 20px;
}


@media screen and (max-width: 959px) {
  .sidebar--dynamic {
    padding: 20px 0;
    text-align: center;
    border-width: 8px 0;
  }

  .sidebar--dynamic>ul {
    margin: 0 auto;
    max-width: 500px;
  }
}




/* WordPress Core CSS (Customized) */
.alignnone {
  margin: 5px 20px 20px 0;
}

.aligncenter,
div.aligncenter {
  display: block;
  margin: 5px auto 20px auto;
}

.alignright {
  float: right;
  margin: 5px 0 20px 20px;
}

.alignleft {
  float: left;
  margin: 5px 20px 20px 0;
}

.aligncenter {
  display: block;
  margin: 5px auto 20px auto;
}

a img.alignright {
  float: right;
  margin: 5px 0 20px 20px;
}

a img.alignnone {
  margin: 5px 20px 20px 0;
}

a img.alignleft {
  float: left;
  margin: 5px 20px 20px 0;
}

a img.aligncenter {
  display: block;
  margin: 5px auto 20px auto;
}

.wp-caption {
  background: #fff;
  border: 1px solid #ccc;
  max-width: 100%;
  padding: 5px 5px 10px;
  text-align: center;
}

.wp-caption.alignnone {
  margin: 5px 20px 20px 0;
}

.wp-caption.alignleft {
  margin: 5px 20px 20px 0;
}

.wp-caption.alignright {
  margin: 5px 0 20px 20px;
}

.wp-caption img {
  border: 0 none;
  height: auto;
  margin: 0;
  max-width: 100%;
  padding: 0;
  width: auto;
}

.wp-caption p.wp-caption-text {
  font-size: 11px;
  line-height: 1.2;
  margin: 0;
  padding: 5px 0 0;
}

.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

.screen-reader-text:focus {
  background-color: #f1f1f1;
  border-radius: 3px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  color: #21759b;
  display: block;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: bold;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000;
}

.navigation {
  margin: 0 0 20px;
  display: block;
}

.navigation .alignleft,
.navigation .alignright {
  width: 50%;
  margin: 0;
  display: block;
}

.navigation .alignright {
  text-align: right;
}

.pagination {
  margin: 40px 0 20px;
  padding-top: 20px;
  text-align: center;
  border-top: 1px solid #ccc;
}

.page-numbers {
  padding: 0 1px;
}

.wp-posts-list,
.wp-search-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.wp-posts-list li,
.wp-search-list li {
  margin-top: 30px;
  list-style-type: none;
}

.wp-posts-list li:first-child,
.wp-search-list li:first-child {
  margin-top: 0;
}

.wp-posts-list h2 {
  margin-bottom: 10px !important;
}

.postmetadata {
  color: #808080;
}

.postmetadata-postdate {
  margin: 0 0 5px;
}

.postmetadata-list {
  margin: 0 0 10px !important;
  padding: 0 !important;
  list-style: none !important;
}

.postmetadata-list li {
  margin: 0;
  padding: 0 10px;
  float: left;
  line-height: 1.2;
  border-left: 1px solid #333;
}

.postmetadata-list li:first-child {
  padding-left: 0;
  border: none;
}

@media only screen and (max-width: 800px) {
  img.alignnone,
  img.aligncenter,
  img.alignright,
  img.alignleft,
  a>img.alignnone,
  a>img.aligncenter,
  a>img.alignright,
  a>img.alignleft,
  .wp-caption,
  .wp-caption.alignnone,
  .wp-caption.alignleft,
  .wp-caption.alignright {
    margin: 0 auto 20px;
    display: block;
    float: none;
  }
}

@media only screen and (max-width: 600px) {
  .postmetadata-list li,
  .postmetadata-list li:first-child {
    margin: 0 0 5px;
    padding: 0;
    float: none;
    border: none;
  }
}
